import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ProfileFacade } from '@mpauth/shared/data-access';
import { IdentityUserProfile } from '@mpauth/shared/domain';
import { UserType } from '@mpk/shared/domain';

import { ProfileRecht } from './profile-recht';

@Injectable({ providedIn: 'root' })
export class UserInfoFacade {
  private readonly _profil$ = new BehaviorSubject<IdentityUserProfile | undefined>(undefined);
  readonly profil$: Observable<IdentityUserProfile>;

  get profil(): IdentityUserProfile | undefined {
    return this._profil$.getValue();
  }

  constructor(private readonly profileFacade: ProfileFacade) {
    this.profil$ = profileFacade.profile$.pipe(
      // This is filtered, because several components rely on firstName/lastName/email.
      // This has to be changed to general userContext.name. Email should not be relevant.
      filter((p): p is IdentityUserProfile => p?.userData?.type === UserType.IdentityUser),
    );

    this.profil$.subscribe({ next: (profil) => this._profil$.next(profil) });
  }

  hasRecht(recht: ProfileRecht): boolean {
    if (!this.profil) {
      return false;
    }

    return this.profil.permissions.some((benutzerRecht) => rechteMatch(benutzerRecht, recht));
  }

  /**
   * Returns an observable that emits true if the user has the given Recht.
   * If the previous backend-request failed or wasn't executed, `undefined` is returned.
   * Optionally it takes a fallback boolean param to be returned if there is no recht or request cannot be executed */
  watchRecht$(recht: ProfileRecht, fallback: boolean): Observable<boolean>;
  watchRecht$(recht: ProfileRecht): Observable<undefined | boolean>;
  watchRecht$(recht: ProfileRecht, fallback?: boolean): Observable<boolean | undefined> {
    return this.profileFacade.profile$.pipe(
      map((profile) =>
        profile ? profile.permissions.some((benutzerRecht) => rechteMatch(benutzerRecht, recht)) : fallback,
      ),
    );
  }
}

function rechteMatch(a: ProfileRecht, b: ProfileRecht): boolean {
  return a.resource === b.resource && a.action === b.action;
}
