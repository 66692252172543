import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { wrapInImageFormData } from '@core/shared/util';
import { Profile, UpdateProfile } from '@mp/auth/profile/domain';

const apiBasePath = 'api/auth/profile';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  constructor(private readonly http: HttpClient) {}

  fetchProfile(): Observable<Profile> {
    return this.http.get<Profile>(apiBasePath);
  }

  updateProfile(dto: UpdateProfile): Observable<Profile> {
    const { image, ...rest } = dto;
    const formData: FormData = wrapInImageFormData(image, 'image', rest);

    return this.http.put<Profile>(apiBasePath, formData);
  }
}
