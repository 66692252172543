import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProfileEffects, ProfileFacade, profileFeatureKey, profileReducer } from '../store';

export function provideProfileDataAccess(): EnvironmentProviders[] {
  return [
    importProvidersFrom(StoreModule.forFeature(profileFeatureKey, profileReducer)),
    importProvidersFrom(EffectsModule.forFeature([ProfileEffects])),
    makeEnvironmentProviders([ProfileFacade]),
  ];
}
