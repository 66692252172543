import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ActiveOrganizationService } from '../services';

const activeOrganizationHeader = 'x-active-organization';

@Injectable()
export class ActiveOrganizationInterceptor implements HttpInterceptor {
  constructor(private readonly activeOrganizationService: ActiveOrganizationService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const activeOrganizationId = this.activeOrganizationService.activeOrganizationId();

    if (activeOrganizationId) {
      req = req.clone({
        setHeaders: {
          [activeOrganizationHeader]: activeOrganizationId,
        },
      });
    }

    return next.handle(req);
  }
}
