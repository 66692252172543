import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map, tap } from 'rxjs/operators';

import { ActiveOrganizationService } from '@mp/auth/active-organization/data-access';

import { ProfileService } from '../services';

import { ProfileActions } from './profile.actions';

@Injectable()
export class ProfileEffects {
  fetchProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.fetchProfile),
      fetch({
        run: () => {
          return this.profileService
            .fetchProfile()
            .pipe(map((profile) => ProfileActions.fetchProfileSuccess({ profile })));
        },
        onError: () => {
          return ProfileActions.fetchProfileError();
        },
      }),
    );
  });

  updateProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.updateProfile),
      fetch({
        run: ({ dto }) => {
          return this.profileService
            .updateProfile(dto)
            .pipe(map((profile) => ProfileActions.updateProfileSuccess({ profile })));
        },
        onError: () => {
          return ProfileActions.updateProfileError();
        },
      }),
    );
  });

  setActiveOrganization$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProfileActions.fetchProfileSuccess),
        tap(({ profile }) => {
          if (profile.activeOrganization) {
            this.activeOrganizationService.setActiveOrganizationId(profile.activeOrganization.organizationId);
          }
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly profileService: ProfileService,
    private readonly activeOrganizationService: ActiveOrganizationService,
  ) {}
}
